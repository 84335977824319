import React from "react"
import Page from "gatsby-theme-nieuwbouw/src/components/Page";

const Template = () => {

  return (
    <div className="vh-100 d-flex align-items-center">
      <h1 className="mx-auto">De pagina die u zocht kan niet worden gevonden</h1>
    </div>
  )
}

export default () => {
  return (
    <Page pageTitle="404" pageDescription="Deze pagina is niet gevonden">
      <Template />
    </Page>
  )
}